import { GetStaticPaths, GetStaticProps, NextPage } from "next";
import React from "react";

import { REVALIDATE_TIME_HIGH_PRIORITY_SECONDS } from "../../../revalidation";
import ContentfulPage from "../../components/ContentfulPage";
import ErrorPage from "../../components/ErrorPage";
import { getPageBySlug, PageLikeEntry } from "../../lib/contentful";
import removeCircularReferencesFromEntry from "../../lib/contentful/remove-circular-references-from-entry";
import { HOME_SLUG_BY_LOCALE, Locale, locales } from "../../lib/i18n";

export interface IndexPageProps {
  entry?: PageLikeEntry;
  notFound?: boolean;
}

const IndexPage: NextPage<IndexPageProps> = ({ entry, notFound }) => {
  if (!entry || notFound === true) {
    return <ErrorPage />;
  }

  return <ContentfulPage entry={entry} />;
};

type URLParams = { locale: string };

const notFound = {
  props: {
    notFound: true,
  },
};

export const getStaticProps: GetStaticProps<
  IndexPageProps,
  URLParams
> = async ({ params, preview }) => {
  try {
    if (params === undefined) {
      return notFound;
    }

    const { locale } = params;
    if (locales.includes(locale as Locale)) {
      const entry = await getPageBySlug(
        HOME_SLUG_BY_LOCALE[locale],
        locale,
        preview,
      );

      return {
        props: {
          entry: removeCircularReferencesFromEntry(entry),
        },
        revalidate: REVALIDATE_TIME_HIGH_PRIORITY_SECONDS,
      };
    }
    return notFound;
  } catch (error) {
    return notFound;
  }
};

export const getStaticPaths: GetStaticPaths = async () => {
  return {
    paths: locales.map(locale => ({
      params: { locale },
    })),
    fallback: "blocking",
  };
};

export default IndexPage;
